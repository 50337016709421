import React, {Component} from 'react'

class connectedTable extends Component{
    constructor(props){
        super(props);
        this.state={
            users:props.users
            //selectedRow:-1
        }
        console.log(this.state.users);
        this.handleClick=this.handleClick.bind(this);
    }
    //add user to list... dont need anymore, users are being handled by parent state
    newUser(user){
        this.setState({users:[...this.state.users,user]})
    }
    //also useless, useres are handled in parent
    componentDidMount(){
        this.newUser({status:'login', user:'tone'});
    }
    //Handling click in list, sending username to parent.
    handleClick(event){
        //this.setState({selectedRow:event.currentTarget.id});
        this.props.handleClick(event.target.innerHTML);
    }

    render(){
        const user = this.props.users.map((user,index)=>{
            return(
                <tr id={index} onClick={this.handleClick}>
                    <td>
                        {createCircle(user.status)}
                    </td> 
                    <td>{user.user}</td>
                </tr>
        )})
        return(
            <div>
                <table className='table table-hover'> 
                    <thead>
                        <tr>
                            <th>status</th>
                            <th>Pålogget</th>
                        </tr>
                    </thead>
                    <tbody>
                        {user}
                    </tbody>
                </table>
            </div>
        )
    }
}
//function to create status 'badges'
function createCircle(status){
    let style;
    if(status==='safe'){style={height:'15px', width:'15px', backgroundColor:'green',borderRadius:'50%', display:'inline-block'};}
    else if (status==='connected'){style={height:'15px', width:'15px', backgroundColor:'yellow',borderRadius:'50%', display:'inline-block'};}
    else if (status==='danger'){style={height:'15px', width:'15px', backgroundColor:'red',borderRadius:'50%', display:'inline-block'};}
    else{style = {height:'15px', width:'15px', backgroundColor:'black',borderRadius:'50%', display:'inline-block'}}
    return(
        <span style={style}></span>
    )
}

export default connectedTable;