import React, {Component} from 'react';
import io from 'socket.io-client';
import Modal from 'react-modal';



const customStyles={
    conten:{
        top:'50%',
        left:'50%',
        right:'auto',
        bottom:'auto',
        marginRight:'-50%',
        transform:'translate(-50%,-50%)'
    }
}
Modal.setAppElement('#root');


class userPage extends Component{

    constructor(props){
        super(props);
        this.state ={
            username:'',
            room:this.props.match.params.room,
            message:'',
            messages:[{user:'martin', message:'heidu'}],
            modalIsOpen:false,
            status:'connected'
        }
        console.log(this.props.match.params);
        this.socket = io.connect('localhost:3001');
        /*this.socket.on('userMessage', (data)=>{
            console.log("herherher");
            this.setState({messages:[...this.state.messages,data]});
        })*/
        this.socket.on('adminMessage',(data)=>{
            console.log('herherher');
            this.setState({messages:[...this.state.messages, data]})
        })
        this.socket.on('reconnectRequest', ()=>{
            console.log('we are bak');
            this.connectUser();
        })
        console.log('herda');
        if(checkUsername()){
            this.state.modalIsOpen=false;
            this.state.username = localStorage.getItem('username'); 
            //this.connectUser();
            this.connectUser();
        }else{this.state.modalIsOpen=true;}

        this.connectUser = this.connectUser.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.recieveMessage = this.recieveMessage.bind(this);
        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.createUsername = this.createUsername.bind(this);
        this.registerUser = this.registerUser.bind(this);
        this.updateStatus = this.updateStatus.bind(this);
    }

    componentDidMount(){
        window.addEventListener('beforeunload',()=>{
            this.socket.emit('closed',{
                user:this.state.username,
                room:this.state.room
            })
        })
        if(this.state.username!==''){
            this.getUserMessages();
        }
    }

    //Funksjoner for modal, brukes til brukernavn
    openModal(){
        this.setState({modalIsOpen:true});
    }

    afterOpenModal(){
        this.subtitle.style.color = '#f00';
    }

    closeModal(){
        this.setState({modalIsOpen:false});
    }


    createUsername(event){
        this.setState({username:event.target.value});
        console.log(this.state.username);
    }
    //er ikke i mål enda her, men blir
    registerUser(){
        this.socket.emit('joinRoom',{
            room:this.props.match.params.room,
            username:this.state.username,
            status:'connected'
        })
        localStorage.setItem('username', this.state.username);
        sessionStorage.setItem('room',this.props.match.params.room)
        this.closeModal();
        this.getUserMessages();
    }
    connectUser(){
        this.socket.emit('joinRoom',{
            room:this.state.room,
            username:this.state.username,
            status:'connected'
        })
        sessionStorage.setItem('room', this.state.room);
    }

    recieveMessage(message){
        this.setState({messages:[...this.state.messages, message]});
    }

    sendMessage(){
        this.socket.emit('sendMessage', {
            user:this.state.username,
            message:this.state.message,
            room:this.state.room
        })
        this.setState({messages:[...this.state.messages,{user:this.state.username, message:this.state.message}]});
        this.setState({message:''});
    }
    //oppdater status til brukeren
    updateStatus(){
        console.log('tester status');
        this.socket.emit('updateStatus',{
            user:this.state.username,
            room:this.state.room,
            status:'danger'
        });
        this.setState({status:'danger'});
    }
    
    getUserMessages(){
        let data = {
            room:this.state.room,
            name:this.state.username
        }
        fetch('/getUserMessages', {
            method:'POST',
            headers:{'Content-type':'application/json'},
            body:JSON.stringify(
                data
            )
        }).then(res=>res.json()).then(data=>{
            this.setUserMessages(data);
        })
    }

    setUserMessages(data){
        let temp = []
        data.messages.forEach(element=>{
            console.log(element.message);
            temp.push({user:element.name.slice(1,-1),message:element.message.slice(1,-1)});
        })
        this.setState({messages:temp});
    }

    render(){
        //let list = ["martin", "maja", "tore"];
        const messages = this.state.messages.map(message=>{
            return(
                <div>{message.user} : {message.message}</div>
            )
        });
        
        return(
            <div className='container' style={{height:'100vh'}}>
                <Modal isOpen={this.state.modalIsOpen} onAfterOpen={this.onAfterOpen} onRequestClose={this.closeModal} style={customStyles}
                    contentLabel='example'
                >

                    <form onSubmit={this.handleSubmit}>
                        <div className = 'form-group'>
                            <label htmlFor = "name">Navn:</label>
                            <input type="text" value={this.state.username} className='form-control' id='name' placeholder="Navn" onChange={this.createUsername}/>
                        </div>
                        <div className = 'form-check form-check-inline'>
                            <button type='submit' className = 'btn btn-primary' onClick={this.registerUser}>login</button>                 
                        </div>
                    </form>

                </Modal>
                <div className='row' style={{height:'75%'}}>
                    <div className ='col'>
                        <div className='card'>
                            <div className='card-body'>
                                <div className = 'card-title'>Chattefelt</div><hr/>
                                <div className = 'messages'>
                                    {messages}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                
                
                
                
                <div className='row' style={{height:'25%'}}>
                    <div className='col'>
                        <div className='card'>
                            <div className='card-footer'>
                                <input type='text' placeholder='melding' value={this.state.message} onChange={event=>this.setState({message:event.target.value})} className='form-control'/>
                                <br/>
                                <button onClick={this.sendMessage} className='btn btn-primary form-control'>Send</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}
function checkUsername(){
    console.log(localStorage.getItem('username'))
    if(localStorage.getItem('username')===null){
        console.log('skal komme modal')
        return false;
    }else{console.log('skal ikke komme modal');return true}
}

export default userPage;