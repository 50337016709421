import React, {Component} from 'react';

class register extends Component{

    constructor(props){
        super(props);
        this.state={
            firstname:'',
            lastname:'',
            email:'',
            password:''
        }
        this.registerFirstname=this.registerFirstname.bind(this);
        this.registerLastname=this.registerLastname.bind(this);
        this.registerEmail = this.registerEmail.bind(this);
        this.registerPassword=this.registerPassword.bind(this);
        this.registerUser = this.registerUser.bind(this);

    }

    render(){
        return(
            <form onSubmit={this.handleSubmit}>
                <div className='form-group'>
                    <label htmlFor = 'firstname'>Skriv navn</label>
                    <input type='text' value={this.state.firstname} className='form-control' id='firstname' placeholder="skriv inn fornavn" onChange={this.registerFirstname}></input>
                    <input type='text' value={this.state.lastname} className='form-control' id="lastname" placeholder='Skriv inn etternavn' onChange={this.registerLastname}></input>
                </div>
                <div className='form-group'>
                    <label htmlFor='email'>E-mail:</label>
                    <input type='email' value={this.state.email} className='form-control' id = 'username' placeholder="skriv inn din e-post" onChange={this.registerEmail}></input>
    
                </div>
                <div className='form-group'>
                    <label htmlFor='password'>passord:</label>
                    <input type='password' value={this.state.password}className='form-control' id='password' placeholder="skriv inn passord" onChange={this.registerPassword}></input>
                </div>
                <button type='submit' className='btn btn-primary' onClick={this.registerUser}>Registrer</button>
                
            </form>)
    }


    registerUser(){
        console.log("start register");
        let data = {
            firstname:this.state.register.firstname,
            lastname:this.state.register.lastname,
            email:this.state.register.email,
            password:this.state.register.password
        }
        console.log(data);
        fetch("/registerUser",{
            method:'POST',
            headers:{'Content-Type':'application/json'},
            body:JSON.stringify(
                data
            )
        }).then((res)=>{
            console.log(res);
        });

    };
    registerFirstname(event){
        this.setState({firstname:event.target.value});
        console.log(this.state);
    }
    registerLastname(event){
        this.setState({lastname:event.target.value});
        console.log(this.state);
    }
    registerEmail(event){
        this.setState({email:event.target.value});
    }
    registerPassword(event){
        this.setState({password:event.target.value});
    }
}
export default register;