import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.css';
import Login from './components/login';
import Register from './components/register';
import MainPage from './components/mainPage';
import UserPage from './components/userPage';
import {BrowserRouter, Route} from 'react-router-dom';


Notification.requestPermission(function(status){
    console.log("Notification status:", status);
});

function urlBase64ToInt8Array(url){
    const padding = '='.repeat((4 - url.length % 4) % 4);
    const base64 = (url + padding)
      .replace(/-/g, '+')
      .replace(/_/g, '/');
  
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
  
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

function subscribe(){
    return navigator.serviceWorker.register('/worker.js').then((reg)=>{
        const options = {
            userVisibleOnly:true,
            applicationServerKey:urlBase64ToInt8Array('BIzUrcAvjoJHpQG_MxMo5YahWo4wHhqbnE5SDkbM_X1RU-JQW9qRvOtUGfdeLjdWA1sf5ACnA1v-qxadabD1dHI')
        };
        return reg.pushManager.subscribe(options);  
    }).then((sub)=>{
        console.log("heiehei")
        console.log("subscribed", JSON.stringify(sub));
        return sub;
    })
    
}
/*
function sendSubscription(sub){
    return fetch('/postSubscription', {
        method:'post', 
        headers:{
            'content-type':'application/json'
        },
        body:JSON.stringify(sub)
    })
    .then((response)=>{
        console.log("response");
        if(!response.ok){
            throw new Error('Status error');
        }
        return response.json();
    })
    .then((data)=>{
        if(!(data.data && data.data.success)){
            //throw new Error('Response error');
        }
    });
}*/

if('serviceWorker' in navigator){
    navigator.serviceWorker.register('/worker.js').then(function(reg){
        console.log("Serviceworker");

        reg.pushManager.getSubscription().then((sub)=>{
            console.log(sub);
            if(sub===null){
                console.log("sum");
                
                subscribe();
            }else{
                console.log("subscription", sub.endpoint);
            }
            //sendSubscription(sub);
        })


    }).catch(function(err){
        console.log("noe funka ikke" , err);
    })
}


/*function displayNotification(){
    if(Notification.permission==='granted'){
        navigator.serviceWorker.getRegistration().then(function(reg) {
            var options = {
                body:'Notification body',
                vibrate:[100,50,100],
                data:{
                    dateOfArrival:Date.now(),
                    primarKey:1
                }
            };
            reg.showNotification("hei verden", options);
        });
    }
}*/

//switch between login form and mainpage;;;;;


//if (login){loginForm();}else{mainPage()};
routerTest();

function routerTest(){
    ReactDOM.render(
        <BrowserRouter>
            <div>
                <Route exact path='/' component={Login}></Route>
                <Route path='/register' component={Register}></Route>
                <Route path='/mainpage' component={MainPage}></Route>
                <Route path='/userTest/:room' component={UserPage}></Route>
            </div>
        </BrowserRouter>,document.getElementById('root')
    )
}



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
