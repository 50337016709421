import React, {Component} from 'react';

class login extends Component{

    constructor(props){
        super(props);
        this.state ={
            email:'',
            password:''
        }
        this.loginEmail=this.loginEmail.bind(this);
        this.loginPassword=this.loginPassword.bind(this);
        this.loginUser = this.loginUser.bind(this);

        this.showRegister = this.showRegister.bind(this);
    }
    render(){
        return(
            <form onSubmit={this.handleSubmit}>
                <div className = 'form-group'>
                    <label htmlFor = "email">Epost:</label>
                    <input type="text" value={this.state.email} className='form-control' id='email' placeholder="skriv epost" onChange={this.loginEmail}/>
                </div>
                <div className = 'form-group'>
                    <label htmlFor="password">Passord:</label>
                    <input type="password" value={this.state.password} className="form-control" id = "password" placeholder="Skriv passord" onChange={this.loginPassword}/>
                </div>
                <div className = 'form-check form-check-inline'>
                    <button type='submit' className = 'btn btn-primary' onClick={this.loginUser}>login</button>
                    <button type='submit' className='btn btn-primary' onClick={this.showRegister}>Registrer</button>                  
                </div>
            </form>
        )
    }
    handleSubmit(event){
        event.preventDefault();
    }
    showRegister(){
        this.props.history.push('/register');
    }
    
    loginEmail(event){
        this.setState({email:event.target.value});
    }
    loginPassword(event){
        this.setState({password:event.target.value});
    }
    loginUser(){
        console.log("login starter");
        let data = {
            email:this.state.email,
            password:this.state.password
        }
        fetch("/userLogin",{
            method:'POST',
            headers:{'Content-Type':'application/json'},
            body:JSON.stringify({
                data
            })
        }).then(res=>res.json()).then((data)=>{
            console.log(data.data);
            console.log(data.token);
            sessionStorage.setItem('token',data.token);
            if(data.data.login){this.props.history.push('/mainpage')}
            else{};
            
        })
            //console.log(res)
            //console.log(res.data);
            //if(res.result){this.props.history.push('/mainpage')}
    }
}
export default login;