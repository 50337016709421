import React, {Component} from 'react';
import ConnectedTable from './connectedTable';
import io from 'socket.io-client';

class mainPage extends Component{
    constructor(props){
        super(props);
        let room = createRoom();
        this.state = {
            users:[],
            room:room.room,
            messages:[],
            message:'',
            selectedUser:null,
            selectedSocket:null
        }
        
        this.ConnectedTable=ConnectedTable;
        this.getData();
        this.socket = io.connect('localhost:3001');
        this.socket.emit('createRoom',{
            room:this.state.room,
            new:room.new
        })
        console.log(room.new);
        if(!room.new){
            this.reconnectUsers();
        }

        this.socket.on('newUser',(data)=>{
            console.log(data);
            let index = this.findUser(data.username);
            if(index!==-1){
                this.updateUserConnection({status:'connected', user:data.username, socket:data.socket}, index);
            }else{
                console.log('noe fungererer ikke');
                this.newUser({status:'connected',user:data.username, socket:data.socket});
            }
        })
        this.socket.on('userMessage',(message)=>{
            this.setState({messages:[...this.state.messages, message]})
            console.log(message);
        })
        this.socket.on('closed',(data)=>{
            console.log(data);
            //this.updateUser(data)
        })
        this.socket.on('updateStatus',(data)=>{
            this.updateUser(data);
        })
        

        this.createMessage = this.createMessage.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.updateUser = this.updateUser.bind(this);
        this.findUser = this.findUser.bind(this);
        this.updateUserConnection = this.updateUserConnection.bind(this);
        this.selectAllMessages = this.selectAllMessages.bind(this);
        this.setSelectedSocket = this.setSelectedSocket.bind(this);
    }
    componentDidMount(){   
        let data ={
            room:this.state.room
        }     
        fetch('/getMessages', {
            method:'POST',
            headers:{'Content-type':'application/json'},
            body:JSON.stringify(
                data
            )            
        }).then(res=>res.json()).then(data=>{
            this.setUserMessages(data);
        })

    }

    //helper function to find a reconnecting user
    findUser(user){
        let index =this.state.users.findIndex((element)=>{
            return user===element.user
        })
        return index;
    }
    //Connecting a new user to chat
    newUser(user){
        console.log(user);
        this.setState({users:[...this.state.users,user]})
    }
    //Update a reconnecting users socket id
    updateUserConnection(data, index){
        console.log(data.socket);
        let temp = [...this.state.users];
        temp[index] = data;
        this.setState({users:temp});
    }
    //Update a users status.. //danger, connected, safe
    updateUser(data){
        let temp = [...this.state.users]
        let index = temp.findIndex((element)=>{
            return element.user===data.user;
        });
        temp[index] = {status:data.status,user:data.user}
        this.setState({users:temp});
        console.log(this.state.users);
    }

    //Recieve a message and add to state
    createMessage(event){
        this.setState({message:event.target.value});
    }

    //send a message to all connected users
    sendMessage(){
        if(this.state.selectedSocket===null){
            this.socket.emit('adminMessage', {
                message:this.state.message,
                room:this.state.room,
            });
        }else{
            this.socket.emit('directMessage', {
                message:this.state.message,
                socket:this.state.selectedSocket
            });
        }
        this.setState({messages:[...this.state.messages, {user:'krisegruppa', message:this.state.message}]});
        this.setState({message:''});
    }

    //Method for handling click in connectedTable user list
    handleClick(val){
        if(this.state.selectedUser===null || this.state.selectedUser!==val){
            this.setState({selectedUser:val},()=>{
                this.selectUserMessages();
                this.setSelectedSocket();
            });
        }else{
            this.selectAllMessages();
        }
        
    }
    //show all messages, and reset selected user states
    selectAllMessages(){
        let temp = [...this.state.messages];
        temp.forEach((data, index)=>{
            temp[index] = {user:data.user, message:data.message, selected:true};
        })
        this.setState({messages:temp});
        this.setState({selectedUser:null});
        this.setState({selectedSocket:null});
    }
    //only display messages from a selected user
    selectUserMessages(){
        let temp = [...this.state.messages];
        temp.forEach((data, index)=>{
            if(data.user===this.state.selectedUser || data.user ==='krisegruppa'){
                temp[index] = {user:data.user, message:data.message,selected:true};
            }else{
                temp[index] = {user:data.user, message:data.message, selected:false};
            }
        })
        this.setState({messages:temp});
    };
    //function to set selected users socket, for individuall messages
    setSelectedSocket(){
        let index = this.state.users.findIndex((user)=>{
            return this.state.selectedUser===user.user
        })
        this.setState({selectedSocket:this.state.users[index].socket});
        
    }
    setUserMessages(data){
        let temp = [];
        data.messages.forEach(element=>{
            temp.push({user:element.name.slice(1,-1), message:element.message.slice(1,-1), selected:true});
        })
        this.setState({messages:temp});
    }

    reconnectUsers(){
        console.log('reconnect');
        this.socket.emit('reconnectUser',{
            room:this.state.room
        })
    }

    render(){
        console.log(this.state.room);
        let link = 'localhost:3000/userTest/'+this.state.room;
        let messages = this.state.messages.map(messages=>{
                if(messages.selected===true){
                let styles = null;
                if(messages.user==='krisegruppa'){
                    styles = {textAlign:'right'}
                }else{styles = {textAlign:'left'}}
                return(
                    <li className='list-group-item' style={styles}><small>{messages.user}:</small><br/>{messages.message}</li>
                )}
        })
        
        return(
            <div className='container-fluid'>
                <div className = 'row'>
                    <div className='col-2'>
                        <ConnectedTable users={this.state.users} handleClick={this.handleClick.bind(this)}/>

                    </div>
                    <div className='col-10' style={{ height:'100vh'}}>
                        <div className='row' style={{height:'7vh', backgroundColor:'blue'}}>
                            <textarea className='form-control rounded-0' value={link} readOnly></textarea>
                        </div>

                        <div className = 'row' style={{height:'71vh', backgroundColor:'purple'}}>
                            <ul className='list-group list-group-flush' style={{width:'100%'}}>
                                {messages}
                            </ul>
                        </div>
                        <div className='row' style={{height:'22vh'}}>
                            <div className='form-group'>
                                <label htmlFor='message'>Melding:</label>
                                
                                <textarea rows='3' cols='50' value={this.state.message} className='form-control' placeholder='Melding' onChange={this.createMessage}></textarea>
                                <button type='submit' className='btn btn-primary' onClick={this.sendMessage}>Send</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    //Method for fetching data... do believe it to be useless right now... THe need for it has been removed
    async getData(){
        console.log("her");
        let data = {
            token:sessionStorage.getItem('token')
        };
        await fetch('/getData',{
            method:'POST',
            headers:{'Content-type':'application/json'},
            body:JSON.stringify(
                data
            )
        }).then(res=>res.json()).then(data=>{
            this.setState({users:data.users,groups:data.groups});
            console.log(this.state.users);
        })
            

    }
       
}

//Function to create a room id for users to connect to.
function createRoom(){
    var room = '';
    console.log(sessionStorage.getItem('room'));
    if(sessionStorage.getItem('room')===null){
        room={room:Math.random().toString(36).substring(2,3),new:true};
    }else{
        room={room:sessionStorage.getItem('room'),new:false};
    }
    sessionStorage.setItem('room',room.room);
    return room;
}
export default mainPage;